.star img {
    transform: scale(.2);
    animation: starScale 5s linear;
    animation-fill-mode: forwards;
}

@keyframes starScale {
    0% {
        transform: scale(.2);
        opacity: .5;
    }

    100% {
        transform: scale(3);
        opacity: 1;
    }
}