.trucks .swiper-slide {
  transition: all 500ms linear;
  transition-delay: 500ms;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.trucks .swiper-slide.swiper-slide-active {
  transform: scale(1.3) !important;
  -webkit-filter: blur(0);
  -moz-filter: blur(0);
  -o-filter: blur(0);
  -ms-filter: blur(0);
  filter: blur(0);
}

.trucks .swiper-slide img {
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
}

@media (max-width: 768px) {
  .trucks .swiper-slide.swiper-slide-active {
    transform: scale(1.2) !important;
  }
}
